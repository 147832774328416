import styled from "styled-components"

export const ProductCard = styled.div`
    font-family: 'Ubuntu', sans-serif;
    border-radius: 5px;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
    background-color: #ffffff;

    & h3 {
        margin: 0px;
        padding: 0px;
    }

    & p {
        margin: 10px 0px 0px 0px;
        padding: 0px;
    }
    
    & img {
        width: 100%;
        border-radius: 5px 5px 0px 0px;
    }

    & div {
        padding: 15px;
    }

    & button {
        width: 100%;
        margin-top: 15px;
        font-family: 'Ubuntu', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        border: 3px solid #000000;
        border-radius: 5px;
        padding: 10px;
        background-color: #ffffff;
    }
`