import React from 'react'
import { Container } from './Cart.style'

export default function Cart() {
    return (
        <Container>
            <h1>Carrinho</h1>
        </Container>
    )
}
