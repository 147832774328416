import React from 'react'
import { ProductCard } from './Product.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

export default function Product({ data }) {
    let product = data;

    return (
        <ProductCard key={product.id}>
            <img src={product.image} alt={product.name} />
            <div>
                <h3>{product.name}</h3>
                <p>{product.price}</p>
                <p>{product.description}</p>
                <button onClick={() => {
                    console.log('Added to cart')
                }}><FontAwesomeIcon icon={faShoppingCart} /> Adicionar ao carrinho</button>
            </div>
        </ProductCard>
    )
}
