import React, { useState, useEffect } from 'react'
import { Container, ColorButton, ColorsWrapper, Domain, SubmitButton, ValFlag } from './Singup.style'
import Nav from "../../components/Nav/Nav"
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth"
import { doc, setDoc } from "firebase/firestore";
import { app, db } from '../../config/firebase.config'
import { usePasswordValidation } from '../../hooks/usePasswordValidation';

// FALTA VALIDAÇÃO DE SUBDOMINIO, CATCH DO ERRO DE EMAIL JÁ CADASTRADO
// REDIRECIONAR PRA PAGAMENTO NO SUCESSO DO CADASTRO
// REDIRECIONAR PARA PAGINA DE FINALIZAÇÃO NO SUCESSO DO PAGAMENTO

export default function Signup() {
    //const [availableSubDomain, setAvailableSubDomain] = useState(true) 
    const [subDomain, setSubDomain] = useState("")
    const [selectedColor, setColor] = useState("#161c2d")
    //const [loading, setLoading] = useState(false)
    const [passwordMatch, setMatch] = useState(false)
    const [password, setPassword] = useState({
        firstPassword: "",
        secondPassword: "",
    });
    const [validLength] = usePasswordValidation({
        firstPassword: password.firstPassword,
        secondPassword: password.secondPassword,
    });

    const setFirst = (event) => {
        setPassword({ ...password, firstPassword: event.target.value });
        
    };
    const setSecond = (event) => {
        setPassword({ ...password, secondPassword: event.target.value });
        checkMatch()
    };

    const checkMatch = () => {
        if (password.firstPassword.length > 0 && password.secondPassword.length > 0 && password.firstPassword === password.secondPassword) {
            setMatch(true)
        } else {
            setMatch(false)
        }
    }

    useEffect(() => {
        checkMatch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) 
    const colors = [
        { name: "Preto", value: "#161c2d" },
        { name: "Verde", value: "#42ba96" },
        { name: "Azul", value: "#467fd0" },
        { name: "Roxo", value: "#7c69ef" },
        { name: "Rosa", value: "#e83e8c" },
        { name: "Vermelho", value: "#df4759" },
        { name: "Laranja", value: "#fd9644" },
        { name: "Amarelo", value: "#ffc107" },
    ]

    function OnChangeSubDomain(value) {
        function removePtChars(str) {
            let ptChars = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";
            let nonPtChars = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
            let newString = "";
            for (let i = 0; i < str.length; i++) {
                let change = false;
                for (let a = 0; a < ptChars.length; a++) {
                    if (str.substr(i, 1) === ptChars.substr(a, 1)) {
                        newString += nonPtChars.substr(a, 1);
                        change = true;
                        break;
                    }
                }
                if (change === false) {
                    newString += str.substr(i, 1);
                }
            }
            return newString;
        }

        let transformToDomain = removePtChars(value.target.value.toLowerCase());

        transformToDomain = transformToDomain.replace(/\s/g, '')

        transformToDomain = transformToDomain.replace(/[^a-zA-Z ]/g, "")

        setSubDomain(transformToDomain)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        let auth = getAuth(app);
        createUserWithEmailAndPassword(auth, e.target.email.value, e.target.password.value)
            .then((userCredential) => {
                const userCred = userCredential.user;
                let user = {
                    name: e.target.name.value,
                    storeName: e.target.storeName.value,
                    email: e.target.email.value,
                    subDomain: subDomain,
                    color: selectedColor,
                    id: userCred.uid,
                    isActive: false,
                    products: []
                }
                setDoc(doc(db, "users", user.id), user);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                return (errorCode, errorMessage)
            })

    }

    return (
        <div>
            <Container color={selectedColor}>
                <Nav />
                <form onSubmit={handleSubmit}>
                    <div>
                        <h1>Crie sua loja</h1>
                        <h2>Qual seu nome?</h2>
                        <input required placeholder={"José da Silva"} name="name" />
                        <h2>Qual o nome da sua loja?</h2>
                        <input required placeholder={"Loja do Zé"} onChange={OnChangeSubDomain} name="storeName" />
                        <Domain><span>Seu link:</span><p>{subDomain + '.linkeezap.com'}</p></Domain>

                        <h2>Escolha a cor</h2>
                        <ColorsWrapper>
                            {colors.map((color, index) => (
                                <ColorButton selected={selectedColor === color.value ? true : false} key={index} color={color.value} name={color.name} onClick={(e) => { e.preventDefault(); setColor(color.value) }} />
                            ))}
                        </ColorsWrapper>
                    </div>

                    <div>
                        <h1>Seus dados</h1>
                        <h2>Qual seu email?</h2>
                        <input required name="email" placeholder={"Email"} />
                        <h2>Escolha uma senha</h2>
                        <input required name="password" placeholder={"Senha"} type={'password'} onChange={(e) => {setFirst(e)}} />
                        <h2>Confirme sua senha</h2>
                        <input required name="passwordConfirm" placeholder={"Confirme sua senha"} type={'password'} onChange={(e) => {setSecond(e)}} />
                        {!validLength && <ValFlag>A senha deve ter no mínimo 6 caracteres.</ValFlag>}
                        {!passwordMatch && <ValFlag>As senhas não são iguais.</ValFlag>}
                    </div>
                    <SubmitButton disabled={validLength && passwordMatch ? false : true} type="submit">IR PARA PAGAMENTO</SubmitButton>
                </form>
            </Container>
        </div>
    )
}
