import React from 'react'
import {LandingPage, Brand, Footer} from './Main.style'
import LogoFull from '../../icon/logo_full.svg'
import StoreMockup from '../../icon/store_mockup.png'
import { useNavigate } from 'react-router-dom'

export default function Main() {
    let navigate = useNavigate()

    return (
        <LandingPage>
            <Brand src={LogoFull} alt="logo" />
            <h1>Tenha sua loja no Whatsapp.</h1>
            <button onClick={() => navigate('/signup')}>Criar loja</button>
            <img src={StoreMockup} alt="Linkeezap" />
            <Footer>
                <p>Linkeezap © 2021 - Todos os direitos reservados.</p>
            </Footer>
        </LandingPage>
    )
}
