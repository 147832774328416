import styled from "styled-components";

export const Brand = styled.img`
margin: 10px 0px;
padding: 0px;
width: 150px;
height: 50px;
`

export const Container = styled.div`
display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`
