import React from 'react'
import LogoWhite from '../../icon/logo_white.svg'
import { Brand, Container} from './Nav.style'

export default function Nav() {
    return (
        <Container>
            <Brand src={LogoWhite} alt="Logo" />
        </Container>
    )
}
