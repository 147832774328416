import styled from "styled-components";

export const ProductsGrid = styled.div`
    padding: 20px;
    display: grid;
    margin: 0px 20%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 20px 20px;
    grid-template-areas:
        ". . . ."
        ". . . ."
        ". . . .";
    grid-area: body;
    /* Extra small devices (phones, 600px and down) */
    @media only screen and (max-width: 600px) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 20px 20px;
        margin: 0px 0%;
        grid-template-areas:
        "."
        ".";
        grid-area: body;
    }
    /* Small devices (portrait tablets and large phones, 600px and up) */
    @media only screen and (min-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 20px 20px;
        margin: 0px 10%;
        grid-template-areas:
        ". ."
        ". .";
        grid-area: body;
    }
    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 20px 20px;
        margin: 0px 10%;
        grid-template-areas:
        ". . . "
        ". . . "
        ". . . ";
        grid-area: body;
    }
    /* Large devices (laptops/desktops, 992px and up) */
    @media only screen and (min-width: 992px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr;
        gap: 20px 20px;
        margin: 0px 20%;
        grid-template-areas:
        ". . . "
        ". . . "
        ". . . ";
        grid-area: body;
    }
`