import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Main from './views/Main/Main'
import Signup from "./views/Signup/Signup";
import Catalog from './views/Catalog/Catalog'
import Store from './views/Store/Store'
import Editor from './views/Editor/Editor'
import Cart from './views/Cart/Cart'

let users = [
  {
    id: 'u240rfj3ff9831hjf89j2f89',
    name: 'Zezinho',
    mail: 'zezinho@mail.com',
    phone: '+55 (11) 99999-9999',
    store: {
      url: 'lojadozezinho',
      storeName: 'Loja do Zezinho',
      storeLogo: 'http://via.placeholder.com/500x500',
      color: '#893dc7',
      products: [
        {
          id: '213uf8d8fa8g9ay2y9fa98sdfa',
          name: 'Produto 1',
          price: 'R$ 100,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d8234523452y9fa98sdfa',
          name: 'Produto 2',
          price: 'R$ 90,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d84134g9ay2y9fa98sdfa',
          name: 'Produto 3',
          price: 'R$ 600,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d8fa8g9ay2y914141414dfa',
          name: 'Produto 4',
          price: 'R$ 50,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d8f657624524143213a98sdfa',
          name: 'Produto 5',
          price: 'R$ 130,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        }
      ]
    }
  },
  {
    id: 'u240rfj3ff9831hjf89j2f89',
    name: 'Joao',
    mail: 'joao@mail.com',
    phone: '+55 (11) 99999-9999',
    store: {
      url: 'lojadojoao',
      storeName: 'João Store',
      storeLogo: 'http://via.placeholder.com/500x500',
      color: '#ff0000',
      products: [
        {
          id: '213uf8d8fa8g9ay2y9fa98sdfa',
          name: 'Produto 1',
          price: 'R$ 100,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d8234523452y9fa98sdfa',
          name: 'Produto 2',
          price: 'R$ 90,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d84134g9ay2y9fa98sdfa',
          name: 'Produto 3',
          price: 'R$ 600,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d8fa8g9ay2y914141414dfa',
          name: 'Produto 4',
          price: 'R$ 50,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d8f657624524143213a98sdfa',
          name: 'Produto 5',
          price: 'R$ 130,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        }
      ]
    }
  },
  {
    id: 'u240rfj3ff9831hjf89j2f89',
    name: 'Osquinha',
    mail: 'osquinha@mail.com',
    phone: '+55 (11) 99999-9999',
    store: {
      url: 'oscarburgers',
      storeName: 'Oscar Burgers',
      storeLogo: 'http://via.placeholder.com/500x500',
      color: '#ff7b00',
      products: [
        {
          id: '213uf8d8fa8g9ay2y9fa98sdfa',
          name: 'Produto 1',
          price: 'R$ 100,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d8234523452y9fa98sdfa',
          name: 'Produto 2',
          price: 'R$ 90,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d84134g9ay2y9fa98sdfa',
          name: 'Produto 3',
          price: 'R$ 600,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d8fa8g9ay2y914141414dfa',
          name: 'Produto 4',
          price: 'R$ 50,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        },
        {
          id: '213uf8d8f657624524143213a98sdfa',
          name: 'Produto 5',
          price: 'R$ 130,00',
          image: 'http://via.placeholder.com/500x500',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisl eget consectetur tempor, nisi nisl aliquam eros, eget egestas nunc nisl eget.',
        }
      ]
    }
  }
]

export default function App() {

  const [domain, setDomain] = useState(window.location.host.split('.')[0])
  const [isCatalog, setIsCatalog] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    setDomain(window.location.host.split('.')[0])
    if (domain === "linkeezap" || domain === "localhost:3000") {
      setIsCatalog(false)
    } else {
      let user = users.find(user => user.store.url === domain)
      if (user) {
        setUser(user)
        setIsCatalog(true)
      } else {
        setIsCatalog(false)
      }
    }
  }, []) //eslint-disable-line

  return (
    <BrowserRouter>
      <Routes>
    {
      isCatalog?
      <>
      <Route path="/" element={<Store store={user.store}/>}>
        <Route index element={<Catalog store={user.store}/>} />
        <Route path="carrinho" element = {<Cart />} />
      </Route>
      <Route path="editor" element={<Editor />} />
      </>
      :
      <>
      <Route path="/" element={<Main />} />
      <Route path="signup" element={<Signup />} />
      </>
        }
      </Routes>
    </BrowserRouter>
  )
}
