import React from 'react'
import { ProductsGrid } from './Catalog.style'
import Product from '../../components/Product/Product'


export default function Catalog({ store }) {

    return (
            <ProductsGrid>
                {store.products.map((product, index) => (
                    <Product key={index} data={product} />
                ))}
            </ProductsGrid>

    )
}
