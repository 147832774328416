import React from 'react'
import { Outlet } from 'react-router-dom'
import { Nav, CartButton, Container } from './Store.style'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

export default function Store({store}) {
    let navigate = useNavigate()

    return (
        <Container>
            <Nav color={store.color}>
                <p onClick={() => navigate('/')}>{store.storeName}</p>
                <CartButton onClick={() => navigate('/carrinho')}>
                    <FontAwesomeIcon icon={faShoppingCart} />
                </CartButton>
            </Nav>
            <Outlet />
        </Container>
    )
}
