import styled from "styled-components";

export const Container = styled.div`
font-family: 'Ubuntu', sans-serif;
    display: flex;
    flex-direction: column; 
    align-items: center;
    justify-content: flex-start;
    background-color: ${props => props.color};
    min-height: 100vh;
    transition: all 0.5s;

    & form {
        display: flex;
        flex-direction: column;
        background-color: #FFFFFF;
        border-radius: 5px;
        margin: 0px 20px 20px 20px;
        padding: 20px 0px;
        box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    }

    & form>div {
        margin: 0px 20px 20px 20px;
        border: 1px solid #cfddd9;
        padding: 20px;
        border-radius: 5px;
        background-color: #e4ecea;
    }

    & h1 {
        font-size: 1.6rem;
        margin: 0px;
        padding: 0px;
        color: #424d4d;
        text-align: center;
    }

    & h2 {
        font-size: 1rem;
        color: #7a8d8a;
        font-weight: 500;
        margin: 20px 0px 0px 0px;
        padding: 0px;
    }

    & input {
        border: 1px solid #cfddd9;
        padding: 20px;
        border-radius: 5px;
        font-size: 1.2rem;;
        padding: 10px;
        margin: 10px 0px;
    }

    & input:focus {
        border: 2px solid;
        border-color: #4c5856;
        outline: none;
    }
    `

export const SubmitButton = styled.button`
        font-family: 'Ubuntu', sans-serif;
        border: none;
        padding: 10px;
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 1.2rem;
        font-weight: 700;
        background-color: #25D366;
        color: #FFFFFF;
        margin: 0px 20px;
        cursor: pointer;
        transition: all 0.5s;

        &:disabled {
            background-color: #d1d1d1;
        }
`


export const Domain = styled.div`
font-family: 'Ubuntu', sans-serif;
display: flex;
flex-direction: row;
font-weight: 300;
font-size: 0.8rem;

& p {
    background-color: #cfddd9;
    padding: 5px;
    color: #424d4d;
    border-radius: 5px;
    margin: 0;
}

& span {
    margin-top: 4px;
    margin-right: 5px;
    background-color: transparent;
    
}

`

export const ColorButton = styled.button`
    background-color: ${props => props.color};
    color: #FFFFFF;
    border: none;
    border-radius: 5px;
    height: 30px;	
    width: 30px;
    margin-top: 15px;

    &:hover {
        cursor: pointer;
    }

    ${props => props.selected && `
        &::after {
            content: "👇";
            font-size: 1.2rem;
            position: relative;
            top: -20px;
            left: -3px;
            color: red;
            filter: drop-shadow(0px 5px 5px #ffffff);
        }`
    }

&:focus {
        outline: none;
    }       
    
`

export const ColorsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const ValFlag = styled.div`
    font-family: 'Ubuntu', sans-serif;
    font-size: 0.8rem;
    padding: 5px;
    background-color: #ff9f9f;
    border: 1px solid #ff7979;
    color: #c52828;
    border-radius: 5px;
    margin: 5px 0px;
`