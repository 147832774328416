import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

export const CartButton = styled.button`
    background-color: transparent;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 10px;
    font-size: 1.2em;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
`

export const Nav = styled.div`
    color: #fff;
    background-color: ${props => props.color};
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
        text-align: center;
        font-family: 'Ubuntu', sans-serif;
        font-weight: 500;
        font-size: 1.2em;
        margin: 10px 20px;
        padding: 0px;
    }
`
