import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyDL9Hs1aN-4RX6SkqIrP4iJkVwWrLVpjX4",
    authDomain: "linkeezap.firebaseapp.com",
    projectId: "linkeezap",
    storageBucket: "linkeezap.appspot.com",
    messagingSenderId: "24501459628",
    appId: "1:24501459628:web:4f58d45f9deb7ac82bdd09"
  };  

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)

export const storage = getStorage(app)