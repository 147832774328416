import styled from "styled-components";

export const LandingPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & h1 {
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.6rem;
    font-weight: 700;
    color: #1D1D1B;
    margin-bottom: 30px;
    margin: 0px 30px 30px 30px;
    text-align: center;
  }

  & h2 {
    font-family: 'Ubuntu', sans-serif;
    color: #1D1D1B;
    text-align: center;
    font-size: 1.3rem;
    font-weight: 700;
  }

  & img {
    max-width: 280px;
  }

  & button {
    background-color: #FFF;
    border: 4px solid #1D1D1B;
    border-radius: 10px;
    padding: 10px 20px;
    font-family: 'Ubuntu', sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 30px;
  }

  & button:hover {
    background-color: #1D1D1B;
    color: #FFF;
    cursor: pointer;
  }

  & input {
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    padding: 10px;
    margin: 10px;
    background: #EEEEEE;
    border: none;
    border-radius: 5px;
    text-align: center;
    ::placeholder {
      color: #1D1D1B;
      font-size: 0.9rem;
    }
  }
`

export const ParagraphLeft = styled.p`
  float: right;
  text-align: center;
  align-self: center;
  font-size: 18px;
`

export const ParagraphAvailable = styled.p`
  float: right;
  text-align: center;
  align-self: center;
  font-size: 18px;
  margin-top: -5px;
`

export const DivInput = styled.div`
  display: inline;
`

export const DivForm = styled.div`
  margin-top: auto;
  display: inline;
  width: 80vw;
  height: 90vh;
  border-width: 3px;
  border-radius: 10px;
  border-style: solid;
  border-color: transparent;
  box-shadow: 0px 5px 5px gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Brand = styled.img`
  margin: 30px 0px;
  height: 80px;
`

export const Footer = styled.div`
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-size: 1rem;
  font-weight: 300;
  margin-top: 30px;
  text-align: center;
  color: #888888;
  background-color: #F9F9F9;

  & p {
    margin: 50px 0px;
  }
`
